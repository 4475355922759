@use '../../../../styles';

.container {
  width: var(--site-content-width);
  margin: 5rem auto;
  @media (max-width: 800px) {
    margin: 10rem auto;
    width: var(--site-content2-width);
  }
}

.video {
  @extend .gray-shadow-5;
  position: relative;
  border-radius: 18px;

  iframe {
    width: 100%;
    height: 100%;
    aspect-ratio: 16 / 9;
    border-radius: 18px;
    @media (max-width: 800px) {
      height: 35rem;
    }
  }
}
