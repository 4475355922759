@use '../../../../styles';

.card {
  @extend .gradient-1;
  @extend .gray-shadow-5;
  display: flex;
  width: var(--site-content-width);
  margin: 9rem auto;
  color: styles.$white-3;
  border-radius: 18px;
  padding: 5rem 9rem 10.5rem 9rem;
  @media (max-width: 1200px) {
    flex-direction: column;
    padding: 2rem 3.5rem 4rem 3.5rem;
  }
  @media (max-width: 500px) {
    width: var(--site-content2-width);
    padding: 2rem;
  }
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5em;
}

.image {
  margin-top: 10rem;
  margin-right: -17rem;
  margin-bottom: -15rem;
  @media (max-width: 1200px) {
    align-self: flex-end;
    margin: 3em 0 0 0;
  }

  img {
    width: 608px;
    height: 502px;
    object-fit: contain;
    @media (max-width: 1200px) {
      width: 100%;
      height: 300px;
    }
  }
}
