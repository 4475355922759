@use '../../../../styles';

.container {
  display: flex;
  justify-content: space-between;
  width: var(--site-content2-width);
  margin: 20rem auto 0 auto;
  gap: 8em;
  @media (max-width: 1200px) {
    margin: 0 auto 0 auto;
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 800px) {
    margin: 8rem auto;
    gap: 3em;
  }
}

.image {
  width: 693px;
  height: 1007px;
  @media (max-width: 1200px) {
    height: 500px;
  }
  @media (max-width: 768px) {
    width: 80%;
    height: 250px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin-top: 5rem;
    margin-bottom: -26rem;
    @media (max-width: 1200px) {
      margin: 0;
    }
  }
}

.offer {
  display: flex;
  flex-direction: column;
  gap: 7em;
  align-items: flex-start;
  width: 550px;
  margin-top: 10rem;
  @media (max-width: 1200px) {
    margin-top: 0;
    padding-bottom: 5rem;
    width: 100%;
  }
  @media (max-width: 400px) {
    align-items: center;
  }
}

.button {
  width: 243px;
  height: 40px;
}

.schools {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 4em;
  grid-row-gap: 4em;
  @media (max-width: 400px) {
    grid-template-columns: 1fr;
  }
}

.school {
  display: flex;
  gap: 2em;

  .separator {
    @extend .gradient-1;
    flex-shrink: 0;
    width: 5px;
    height: 55px;
  }
}
