.container {
  width: var(--site-content3-width);
  margin: 10rem auto;
  display: flex;
  flex-direction: column;
  gap: 2.5em;
  text-align: center;
  align-items: center;
  @media (max-width: 768px) {
    width: var(--site-content2-width);
    margin: 8rem auto;
  }
  > h2 {
    width: 60%;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
}

.button {
  width: 273px;
  height: 36px;
  margin-top: 2em;
}

.platformImage {
  width: 1112px;
  height: 649px;
  @media (max-width: 1200px) {
    width: 100%;
    height: auto;
  }
}

.workEffectImage {
  width: 326px;
  height: 220px;
}
