@use '../../../../styles';

.container {
  width: 100%;
  background-color: styles.$green-1;
  color: styles.$white-2;
  padding: 1.5rem 0;
}

.marqueeItem {
  display: flex;
  align-items: center;
  gap: 2.5rem;
  margin-right: 4rem;
  cursor: pointer;
}
