@use '../../../../styles';

.container {
  width: var(--site-content-width);
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(3, 1fr);
  justify-items: center;
  grid-template-areas:
    'photo1 photo1 . . photo2 photo2'
    'photo3 . gheader gheader . photo7'
    'photo4 photo4 photo5 photo5 photo6 photo6';
  margin: 20rem auto 10rem auto;
  @media (max-width: 1200px) {
    row-gap: 3em;
    column-gap: 4em;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-template-areas:
      'gheader gheader gheader'
      'photo1 photo2 photo3'
      'photo4 photo7 photo6'
      '. photo5 .';
  }
  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, max-content);
    grid-template-areas:
      'gheader gheader'
      'photo1 photo2'
      'photo3 photo7'
      'photo4 photo6'
      'photo5 photo5';
  }

  @media (max-width: 550px) {
    justify-content: center;
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(8, max-content);
    grid-template-areas:
      'gheader'
      'photo1'
      'photo2'
      'photo3'
      'photo4'
      'photo6'
      'photo7'
      'photo5';
  }
  img {
    &:nth-child(1n) {
      @media (max-width: 1000px) {
        width: 100% !important;
        height: 100% !important;
        object-fit: cover;
        border-radius: 18px;
      }
      @media (max-width: 550px) {
        width: 80vw !important;
        height: auto !important;
        object-fit: cover;
        border-radius: 18px;
      }
    }
    &:nth-child(1) {
      position: relative;
      top: -2rem;
      grid-area: photo1;
      width: 153px;
      height: 222px;
      @media (max-width: 1200px) {
        top: 0;
      }
    }
    &:nth-child(2) {
      position: relative;
      top: -2rem;
      grid-area: photo2;
      width: 152px;
      height: 222px;
      @media (max-width: 1000px) {
        top: 0;
      }
    }
    &:nth-child(3) {
      grid-area: photo3;
      width: 242px;
      height: 252px;
    }
    &:nth-child(4) {
      position: relative;
      top: -4rem;
      left: 8rem;
      z-index: -1;
      grid-area: photo4;
      width: 150px;
      height: 231px;
      @media (max-width: 1000px) {
        top: 0;
        left: 0;
      }
    }
    &:nth-child(5) {
      grid-area: photo5;
      width: 236px;
      height: 250px;
    }
    &:nth-child(6) {
      position: relative;
      top: -4rem;
      left: -8rem;
      z-index: 1;
      grid-area: photo6;
      width: 150px;
      height: 231px;
      @media (max-width: 1200px) {
        top: -2rem;
      }
      @media (max-width: 1000px) {
        top: 0;
        left: 0;
      }
    }
    &:nth-child(7) {
      grid-area: photo7;
      width: 242px;
      height: 252px;
    }
  }
}

.header {
  width: 447px;
  position: relative;
  top: -8rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 2em;
  grid-area: gheader;

  @media (max-width: 1200px) {
    position: unset;
  }
  @media (max-width: 500px) {
    width: unset;
  }
}
