@use '../../../../styles';

.container {
  width: var(--site-content-width);
  margin: 20rem auto;
  display: grid;
  grid-template-columns: 9fr 11fr;
  grid-column-gap: 12em;
  grid-row-gap: 6em;
  @media (max-width: 800px) {
    width: var(--site-content2-width);
    margin: 8rem auto;
    grid-row-gap: 3em;
  }
  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
}

.header {
  display: flex;
  flex-direction: column;
  align-self: end;
  justify-self: end;

  > :last-child {
    align-self: flex-end;
  }

  @media (max-width: 1200px) {
    justify-self: center;
    text-align: center;

    > :last-child {
      display: none;
    }
  }
}

.customize {
  width: 100%;
  display: flex;
  align-self: end;
  justify-content: space-between;
  gap: 1em;
  align-items: flex-end;

  .customizeText {
    margin-bottom: 4em;
    display: flex;
    align-items: center;
    color: styles.$green-1;
    padding-bottom: 2em;
    padding-right: 6em;
    gap: 3em;
    border-bottom: 1px solid styles.$black-1;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
    svg {
      fill: styles.$green-1;
    }
  }

  @media (max-width: 1200px) {
    display: none;
  }
}

.childrenImages {
  overflow: hidden;
  border-radius: 0 0 18px 18px;
  width: 90%;
  position: relative;
  justify-self: end;
  padding-bottom: 6em;
  > :not(:first-child) {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
  }

  > :nth-child(1) {
    height: 520px;
    @media (max-width: 900px) {
      height: 400px;
    }
  }

  > :nth-child(2) {
    top: 3em;
  }

  > :nth-child(3) {
    top: 6em;
  }

  .childImage {
    @extend .gray-shadow-5;
    border-radius: 18px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: inherit;
    }
  }

  @media (max-width: 1200px) {
    width: max(50%, 250px);
    justify-self: center;
    margin-bottom: 5em;
  }
  @media (max-width: 900px) {
    width: 100%;
  }
}

.packages {
  display: flex;
  flex-direction: column;
  gap: 5em;
  justify-content: space-between;
  > :nth-child(1) .packageContent > :last-child {
    b {
      color: styles.$orange-8;
    }
  }

  > :nth-child(2) .packageContent > :last-child {
    b {
      color: styles.$blue-4;
    }
  }
}

.package {
  display: flex;
  gap: 3em;

  > svg {
    flex-shrink: 0;

    @media (max-width: 650px) {
      display: none;
    }
  }
}

.packageContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding-bottom: 2.5em;
  border-bottom: 1px solid styles.$black-1;

  @media (max-width: 650px) {
    align-items: center;
    text-align: center;
  }
}

.packageHeader {
  display: flex;
  gap: 1em;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 650px) {
    flex-direction: column;
  }
}
