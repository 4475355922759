@use '../../../../styles';

.container {
  width: var(--site-content3-width);
  margin: 20rem auto;
  display: flex;
  flex-direction: column;
  gap: 9em;
  align-items: center;
  h2 {
    text-align: center;
  }
  @media (max-width: 800px) {
    margin: 4rem auto;
    gap: 3em;
  }
}

.benefits {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 3em;
  grid-row-gap: 2em;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
}

.benefit {
  @extend .gray-shadow-5;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  img {
    width: 100%;
  }

  &.regular {
    background-color: styles.$white-4;

    > :last-child {
      padding: 1.5rem 0;
      width: 80%;
    }

    img {
      margin-top: -2rem;
      width: 200px;
      height: 170px;
      object-fit: contain;
      @media (max-width: 800px) {
        margin-top: -2rem;
      }
    }
  }

  &.big {
    @extend .gradient-2;
    color: styles.$white-4;
    grid-row: span 2;
    grid-column: span 2;
    padding: 2rem 0;
    justify-content: space-between;

    img {
      width: 481px;
      height: 368px;
      object-fit: contain;
      @media (max-width: 800px) {
        width: 100%;
        height: 200px;
      }
    }

    @media (max-width: 1200px) {
      order: -1;
      margin-bottom: 2em;
    }

    @media (max-width: 800px) {
      grid-column: span 1;
    }

    h4 {
      @media (max-width: 500px) {
        padding: 0 1em;
      }
    }
  }
}
