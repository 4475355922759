.add-underline:hover {
  text-decoration: underline;
}

h1 {
  font-weight: 700;
  font-size: 6.5rem;
  line-height: 7.6rem;
}

h2 {
  font-weight: 700;
  font-size: 4.8rem;
  line-height: 5.1rem;
}

h3 {
  font-weight: 400;
  font-size: 3.1rem;
  line-height: 4.2rem;
}

h4 {
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 2.8rem;
}

.headline-1a {
  font-weight: 700;
  font-size: 6.5rem;
  line-height: 7.6rem;
}

.headline-1b {
  font-weight: 700;
  font-size: 7.7rem;
  line-height: 9.4rem;
}

.headline-2 {
  font-size: 4.8rem;
  line-height: 6.6rem;
  letter-spacing: 0.2%;
}

.headline-3b {
  font-weight: 700;
  font-size: 3.1rem;
  line-height: 2.8rem;
}

.headline-3 {
  @extend h3;
}

.headline-4 {
  @extend h4;
}

.body-1 {
  font-weight: 400;
  font-size: 2.2rem;
  line-height: 2.8rem;
}

.body-2 {
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.8rem;
}

.body-3 {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2rem;
}

.button-1 {
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.button-2 {
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 2rem;
}

.button-3 {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.6rem;
}

.button-4 {
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.button-5 {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
}

.button-6 {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;
}

.field-1 {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.field-2 {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.6rem;
}

.add-bold {
  font-weight: bold;
}

@media (max-width: 1400px) {
  .headline-1a {
    font-weight: 700;
    font-size: 4rem;
    line-height: 4.5rem;
  }
  .headline-1b {
    font-weight: 700;
    font-size: 5rem;
    line-height: 5.5rem;
  }
  h2 {
    font-weight: 700;
    font-size: 4rem;
    line-height: 4.5rem;
  }
  .add-bold {
    font-weight: bold;
  }
}

@media (max-width: 900px) {
  .headline-1b {
    font-weight: 700;
    font-size: 3.9rem;
    line-height: 4.5rem;
  }
  .add-bold {
    font-weight: bold;
  }
}

@media (max-width: 768px) {
  h1 {
    font-weight: 700;
    font-size: 3.9rem;
    line-height: 4.5rem;
  }

  h2 {
    font-weight: 700;
    font-size: 3rem;
    line-height: 3.2rem;
  }

  h3 {
    font-weight: 400;
    font-size: 2.5rem;
    line-height: 2.6rem;
  }

  h4 {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.6rem;
  }

  .headline-1a {
    font-weight: 700;
    font-size: 4rem;
    line-height: 4.5rem;
  }

  .headline-1b {
    font-weight: 700;
    font-size: 3.3rem;
    line-height: 4rem;
  }

  .headline-3b {
    font-weight: 700;
    font-size: 2.1rem;
    line-height: 1.8rem;
  }

  .body-1 {
    font-weight: 400;
    font-size: 2rem;
    line-height: 2.6rem;
  }

  .body-2 {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.6rem;
  }

  .body-3 {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.8rem;
  }

  .button-1 {
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  .button-2 {
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 2rem;
  }

  .button-3 {
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.6rem;
  }

  .button-4 {
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  .button-5 {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2rem;
  }

  .button-6 {
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2rem;
  }

  .field-1 {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  .field-2 {
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
  .add-bold {
    font-weight: bold;
  }
}
