@use '../../styles';

.header {
  background-color: styles.$black-1;
  color: styles.$white-3;
  flex-wrap: nowrap !important;
}

.socials {
  display: flex;
  align-items: center;
  gap: 1em;
  svg:hover {
    filter: brightness(150%);
  }
}

.login {
  display: flex;
  align-items: center;
  gap: 1.2em;
  &:hover {
    text-decoration: underline;
  }
  span {
    color: styles.$white-3;
  }
}

.left {
  width: 100%;
  column-gap: 0 !important;
}

.links {
  margin: 0 auto;
}
