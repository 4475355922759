@use '../../../../styles';

.container {
  position: relative;
  width: 100%;
  color: styles.$white-2;
  @media (max-width: 768px) {
    height: 40rem;
  }
}

.background {
  width: 100%;
  height: 759px;
  @media (max-width: 768px) {
    height: 40rem;
    object-fit: cover;
  }
}

.consult {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1.5em;

  @media (max-width: 1000px) {
    width: 90%;
  }
}

.button {
  width: 209px;
  height: 50px;
  margin-top: 2.5em;
}
