// Grayscale
$black-1: #000000;
$gray-1: #717171;
$gray-2: #89939e;
$gray-3: #cdcdcd;
$gray-4: #eef1f8;
$gray-5: #c2c2c2;
$gray-6: #e0e0e0;
$gray-7: #424242;
$gray-8: #e1e3e6;
$gray-9: #5a6474;
$gray-10: #d9d9d9;
$gray-11: #e6e6e6;
$gray-12: #eef3f8;
$gray-13: #b9c4d9;
$gray-14: #445275;
$white-1: #ecefff;
$white-2: #f5f5f7;
$white-3: #ffffff;
$white-4: #fbfbfd;
$white-5: #fefefe;

// Blue
$blue-1: #391bb0;
$blue-2: #3b4582;
$blue-3: #3198f4;
$blue-4: #0071e3;
$blue-5: #4196f0;
$blue-6: #dbedff;
$blue-7: #eef5fc;
$blue-8: #4e73f8;
$blue-9: #0053ad;
$blue-10: #39abe2;

// Green
$green-1: #4fb04f;
$green-2: #2d6a49;
$green-3: #009262;
$green-4: #85dd74;

// Orange
$orange-1: #ee6d32;
$orange-2: #ed643a;
$orange-3: #ffa826;
$orange-4: #b98e00;
$orange-5: #fcf9bf;
$orange-6: #d9d4bc;
$orange-7: #fee822;
$orange-8: #f9b235;
$orange-9: #e67601;

// Red
$red-1: #c43025;
$red-2: #e01507;
$red-3: #f0857d;
$red-4: #ff5a4f;
$red-5: #e56b6f;
$red-6: #ff5959;
$red-7: #ef2c2c;
$red-8: #f43c3c;

// Winner
$winner-1: #020129;

// Kindergarten
$regular-1Kinder: #9b34bd;

:export {
  black1: $black-1;
  blue4: $blue-4;
  white2: $white-2;
  white5: $white-5;
  green1: $green-1;
  gray2: $gray-2;
  gray7: $gray-7;
  winner1: $winner-1;
  orange1: $orange-1;
  regular1Kinder: $regular-1Kinder;
}
