@use '../../../styles';

.container {
  display: flex;
  gap: 0.5em;
  align-items: center;
  cursor: pointer;
}

.checkbox {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid styles.$gray-2;
  border-radius: 4px;

  &.selected {
    background-color: styles.$blue-4;
    border: 1px solid styles.$blue-4;
  }
}

.label {
  color: styles.$black-1;

  .selected {
    color: styles.$gray-7;
  }
}
