@use '../../../../styles';

.container {
  width: var(--site-content-width);
  min-height: calc(100vh - 80px);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 7em;
  align-items: center;
  margin: 0 auto;
  padding: 3em 0;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
}

.info {
  display: flex;
  flex-direction: column;
  gap: 2.5em;
  @media (max-width: 768px) {
    gap: 0;
  }

  img {
    @media (max-width: 768px) {
      width: auto;
      height: 200px;
      object-fit: contain;
    }

    margin-top: 2.5em;
  }

  h2 {
    @media (max-width: 768px) {
      margin-top: 1em;
      margin-bottom: 1em;
    }
  }

  @media (max-width: 650px) {
    align-items: center;
    text-align: center;

    img {
      @media (max-width: 768px) {
        width: 80%;
      }
    }
  }
}

.appointment {
  width: max-content;
  @extend .gray-shadow-5;
  background-color: styles.$white-4;
  border-radius: 18px;
  border: 1px solid styles.$gray-2;
  padding: 7em 8.5em;
  @media (max-width: 768px) {
    margin: 4em auto;
  }
  @media (max-width: 550px) {
    width: 100%;
  }
  .inputs {
    display: flex;
    flex-direction: column;
    gap: 2em;
    margin-bottom: 2.5em;
  }

  .checkboxes {
    margin: 1em 0;
    display: flex;
    gap: 1.5em;

    > * {
      flex-shrink: 0;
    }

    @media (max-width: 650px) {
      flex-direction: column;
    }
  }

  > p:last-of-type {
    color: styles.$gray-2;
  }

  .button {
    width: 404px;
    height: 40px;
    margin-top: 3em;
    @media (max-width: 550px) {
      width: 100%;
    }
  }

  @media (max-width: 650px) {
    padding: 2em 3em;
  }
}
