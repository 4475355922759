@use '../../../../styles';

.container {
  display: flex;
  gap: 8em;
  width: var(--site-content-width);
  margin: 20rem auto;
  @media (max-width: 800px) {
    margin: 8rem auto;
    width: var(--site-content2-width);
    gap: 4em;
  }
  > :first-child {
    width: 40%;
    padding-top: 5rem;
  }

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;

    > :first-child {
      width: 100%;
      text-align: center;
    }
  }
}

.stagesWrapper {
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: 1200px) {
    width: 100%;
  }
}

.stages {
  width: 100%;
  padding: 5rem 0;
}

.stage {
  @extend .gray-shadow-5;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: styles.$white-4;
  border-radius: 18px;
  justify-content: space-between;
  gap: 1em;

  .caption {
    width: 100%;
    background-color: styles.$orange-1;
    color: styles.$white-3;
    padding: 1em 2.5em;
    border-radius: 0 0 18px 18px;
    display: flex;
    justify-content: center;
    text-align: center;
    height: 100px;
    p {
      margin: auto 0px;
    }
  }

  img {
    width: auto;
    height: 175px;
    margin-top: -2rem;
  }
}

.navigation {
  display: flex;
  gap: 2.5em;

  svg {
    cursor: pointer;
    user-select: none;
  }

  @media (max-width: 1200px) {
    align-self: center;
  }
}
