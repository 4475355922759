@use '../../../styles';

.container {
  width: 514px;
  padding: 2em 3em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
  overflow-x: hidden;
  > img {
    @media (max-width: 768px) {
      height: 200px;
      object-fit: contain;
      align-self: center;
    }
  }

  h3 {
    color: styles.$blue-9;
  }
}

.buttons {
  margin-top: 2em;
  margin-bottom: 6em;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5em;

  > * {
    cursor: pointer;
  }
}
