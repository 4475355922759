@use '../../../../styles';

.wrapper {
  width: 100%;
  background-color: styles.$white-5;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 18em;
  align-items: center;
  width: var(--site-content-width);
  margin: 0 auto;
  padding-top: 6rem;
  padding-bottom: 12rem;
  @media (max-width: 800px) {
    width: var(--site-content2-width);
    padding-top: 3rem;
    padding-bottom: 6rem;
    gap: 4em;
  }
}

.stats {
  display: flex;
  flex-direction: column;
  gap: 16em;
  @media (max-width: 800px) {
    gap: 8em;
  }

  > :nth-child(1) {
    h2 {
      color: styles.$orange-8;
    }
  }

  > :nth-child(2) {
    h2 {
      color: styles.$orange-9;
    }
  }

  > :nth-child(3) {
    h2 {
      color: styles.$blue-10;
    }
  }
}

.stat {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1em;

  .info {
    display: flex;
    flex-direction: column;
    row-gap: 1.5em;
    width: 30%;

    @media (max-width: 768px) {
      width: 100%;
      text-align: center;
    }
  }

  .image {
    width: 692px;
    height: 320px;
    position: relative;
    z-index: 0;
    @media (max-width: 1000px) {
      width: 80%;
    }
    @media (max-width: 500px) {
      height: 280px;
      width: 80%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @extend .gray-shadow-5;
      border-radius: 18px;
    }

    svg {
      position: absolute;
      width: 400px;
      height: 450px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      @media (max-width: 1000px) {
        width: 100%;
      }
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10em;
  }
}
