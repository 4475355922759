@use '../../../../styles';

.container {
  margin: 20rem auto;
  width: var(--site-content-width);
  display: flex;
  flex-direction: column;
  gap: 6em;
  align-items: center;
  @media (max-width: 800px) {
    width: var(--site-content2-width);
    margin: 8rem auto;
    gap: 4em;
  }
  > :first-child {
    text-align: center;
  }
}

.topics {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 3em;
  grid-row-gap: 2em;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
}

.topic {
  @extend .gray-shadow-5;
  position: relative;
  border-radius: 18px;
  @media (max-width: 1000px) {
    min-height: 30rem;
  }
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: inherit;
  }

  .caption {
    @include styles.border-radius-fix(styles.$white-4);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: styles.$white-4;
    border-radius: 0 0 18px 18px;
    padding: 1.5em 3em 2em 2em;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    height: 12rem;
    > * {
      width: 80%;

      @media (max-width: 1200px) {
        width: 100%;
      }
    }
  }
}
