.mobileHeader {
  position: relative;
  height: 5em;
  display: flex;
  align-items: center;
}
.logo {
  &:nth-child(2) {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 20%;
    text-align: center;
  }
}
