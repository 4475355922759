@use '../../../../styles';

.banner {
  position: relative;
}

.bannerImage {
  width: 100%;
  height: 876px;
  object-fit: cover;
  @media (max-width: 768px) {
    height: 450px;
  }
}

.bannerText {
  color: styles.$white-3;
  position: absolute;
  width: 100%;
  top: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  text-align: center;

  @media (max-width: 768px) {
    top: 50%;
  }
}

.bannerIcon {
  position: absolute;
  bottom: 3.5rem;
  left: 50%;
  transform: translateX(-50%);
}
